/* -Text variations
-----------------------------------------------------------------------------*/
// Take care with the rules for lead-text since it should
// support application directly (e.g. <p class="lead-text">Text</p>) and on
// a container (e.g. <div class="lead-text"><p>One</p><p>Two</p></div>).

@mixin lead-text {
  @include type-size('lead');

  font-weight: $font-weight-semi-bold;
  margin-bottom: rem($text-spacing);
}

.lead-text {
  @include lead-text;
}
.lead-text {
  p,
  ol,
  ul {
    margin-bottom: rem($text-spacing);
  }
}

// Inline text alongside things like icons.
.text {
  vertical-align: middle;

  // Restore default alignment if it appears on its own
  &:only-child {
    vertical-align: baseline;
  }
}
