/* -Selected coworker block with content and coworker
-----------------------------------------------------------------------------*/
.selected-coworker-wrap {
  margin-left: 0;
}
.selected-coworker-inner {
  @include section-margin;
}
.selected-coworker-inner {
  padding: 30px;
  background-color: $color-gray-lightest;
}
.selected-coworker-content {
  margin-bottom: 40px;
}

.selected-coworker-person {
  .coworker-block {
    display: flex;
  }
  .coworker-image {
    max-width: 80px;
  }
  .coworker-info {
    margin-left: 10px;
  }
  .skills {
    margin-bottom: 10px;
  }
}

@include media-min($breakpoint-type-small) {
  .selected-coworker-person {
    .coworker-info {
      font-size: rem(18px);
    }
  }
}

@include media-min(rem(600px)) {
  .selected-coworker-inner {
    padding: 60px;
  }
  .selected-coworker-person {
    .coworker-image {
      max-width: 160px;
    }
    .coworker-info {
      margin-left: 30px;
    }
    .skills {
      margin-bottom: 30px;
    }
  }
}
