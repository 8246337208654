/* -Site footer
-----------------------------------------------------------------------------*/
$site-footer-padding: 60px;
$site-footer-padding-small: 30px;
.site-footer {
  background-color: $color-footer-background;
}
.site-footer-top {
  padding-top: $site-footer-padding-small;
  padding-bottom: $site-footer-padding-small;
}
.site-footer-bottom {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #fff;
}

.site-footer-contact-info {
  .contact-info-name {
    font-weight: $font-weight-bold;
  }
  .contact-info-name,
  .contact-info-address,
  .contact-info-email,
  .contact-info-phone {
    margin-top: 10px;
  }
}

.site-footer-widget {
  position: relative;
  padding: 15px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  .icon--chevron-right {
    width: 10px;
    height: 20px;
  }
}

.overlay-link {
  &:hover,
  &:focus {
    ~ .footer-widget-content {
      .footer-widget-title {
        text-decoration: underline;
      }
    }
  }
}
.footer-widget-image {
  flex-shrink: 0;
  margin-bottom: 15px;
  max-width: 60px;
}
.footer-widget-content {
  flex-grow: 1;
}
.footer-widget-title {
  margin-bottom: 10px;
}
.footer-widget-text p {
  margin-bottom: 0;
}
.footer-widget-arrow {
  margin-left: auto;
}

@include media-min($breakpoint-type-small) {
  .site-footer {
    font-size: rem(20px);
  }
  .site-footer-top {
    padding-top: $site-footer-padding;
    padding-bottom: $site-footer-padding;
  }
  .site-footer-widget {
    padding: 20px;
  }
}

@include media-min(rem(600px)) {
  .site-footer-widget {
    display: flex;
    flex-direction: row;
    padding: 40px;
  }
  .footer-widget-image {
    margin: 0 30px 0 0;
  }

  .footer-widget-arrow {
    margin-left: 30px;
  }
}

@include media-min(rem(900px)) {
  .site-footer-top {
    display: flex;
    justify-content: space-between;
  }
  .site-footer-widget {
    margin: 0 0 0 30px;
  }
}
@include media-min($breakpoint-type-medium) {
  .site-footer {
    font-size: rem(22px);
  }
  .footer-widget-arrow {
    margin-left: 60px;
  }
}
@include media-min(rem(1400px)) {
  .footer-widget-arrow {
    margin-left: 80px;
  }
}
