/* -Icons
-----------------------------------------------------------------------------*/
/* AUTOGENERATED, DO NOT EDIT MANUALLY */

$icon-base-width: 16px;
$icon-base-height: 16px;
.icon {
  width: $icon-base-width;
  height: $icon-base-height;
  flex-grow: 0;
  flex-shrink: 0;
}

$icon-chevron-right-width: 7.1px;
$icon-chevron-right-height: 11.3px;
.icon--chevron-right {
  width: $icon-chevron-right-width;
  height: $icon-chevron-right-height;
}

$icon-chevron-width: 11.3px;
$icon-chevron-height: 7.1px;
.icon--chevron {
  width: $icon-chevron-width;
  height: $icon-chevron-height;
}