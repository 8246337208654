/*===========================================================================*\
      =WordPress Core stuff
\*===========================================================================*/

/* -Gallery shortcode
-----------------------------------------------------------------------------*/
.gallery {
  margin: 0 auto $text-spacing;
  padding-top: 3px;
  p {
    margin: 0;
  }
  br + br {
    display: none;
  }

  .gallery-caption {
    margin-top: 15px;
    color: $color-gray;
    margin: 15px 0 15px;
  }

  .caption-title {
    font-weight: $font-weight-bold;
    display: inline;
  }

  .caption-content {
    position: relative;
    margin: 0;
    display: inline;

    &::before {
      display: inline-block;
      content: '';
      background-color: $color-gray;
      width: 10px;
      height: 1px;
      vertical-align: middle;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.wp-gallery {
  @include grid-container;
  @include grid-pull-bottom;
}
.gallery-item {
  @include grid-margin-bottom;

  text-align: center;

  .gallery-size-thumbnail & {
    max-width: 150px;
  }
  .gallery-size-medium & {
    max-width: 300px;
  }

  .gallery-columns-1 & {
    width: 100%;
  }

  @for $i from 2 to 11 {
    .gallery-columns-#{$i} & {
      @include grid-item(1 / $i);

      @include media-max(rem(450px)) {
        @include grid-item-size(1);
      }

      @include media-between(rem(450px), rem(650px)) {
        @include grid-item-size(1/2);
      }
    }
  }
}
.gallery-item a,
.gallery-link-none .gallery-icon {
  display: block;
}
.gallery-item a {
  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

/* -Image alignments and captions
-----------------------------------------------------------------------------*/
.alignleft,
.alignright {
  max-width: 50%;
}
.alignleft {
  float: left;
  margin: 0.25em 1em 1em 0;
}
.alignright {
  float: right;
  margin: 0.25em 0 1em 1em;
}
.aligncenter {
  display: block;
  margin: 0.25em auto;
}

.alignnone {
  margin: 0 0 1em;
}
p .alignnone {
  margin-bottom: 0;
}

.wp-caption {
  max-width: 99%;
  padding: 5px;
  text-align: center;

  img {
    float: none;
    display: inline;
    margin: 0;
    padding: 0;
    border: 0;
  }
  p {
    margin: 5px 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
  a:hover img,
  a:focus img {
    margin: 0;
    padding: 0;
    border: 0;
  }
  .wp-caption-text {
    font-size: rem(12px);
  }
}
