// -------------------- Color definitions -------------------- //

$color-gray-dark: #333;
$color-blue: #001c75;
$color-blue-medium: #00549a;
$color-dark: #27272b;
$color-gray-light: #eeeeee;
$color-gray-lightest: #f6f6f6;
$color-gray: #6d6d6d;
$color-green: #2c7e27;
$color-green-light: #67b962;
// Lightest text color on white that meets WCAG AA accessibility requirement
$color-lightest-accessible-gray-on-white: #767676;

// -------------------- Semantic assignments -------------------- //
// When possible and logical, use colors through more meaningful
// "use case" names.

// Main body colors
$color-body-foreground: $color-blue;
$color-body-background: $color-gray-light;

// Footer
$color-footer-background: $color-gray-lightest;

// Disabled text
$color-disabled: $color-lightest-accessible-gray-on-white;

// Brand things like selection color
$color-main: $color-blue;

// Links
$color-action: $color-blue;
$color-action-foreground: #fff;

// Forms
$color-form-field-border: #ccc;

// Status
$color-error: #ed404b;
$color-error-foreground: #d71421;
$color-error-background: #fae8e6;
$color-success: #49ad5a;
$color-success-foreground: #337a3b;
$color-success-background: #e7fae6;
