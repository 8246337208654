/* -Search form
-----------------------------------------------------------------------------*/
.search-form {
  display: flex;
  margin-bottom: $section-spacing;

  input[type='search'] {
    min-width: 0;
  }
  input[type='submit'] {
    margin-left: 10px;
  }
}
