/* -Breadcrumb
-----------------------------------------------------------------------------*/
.breadcrumb-list {
  list-style: none;
  margin: 0;
  margin-bottom: 20px;
}
.crumb {
  display: inline-block;
  position: relative;
  margin-right: 20px;
  font-size: rem(17px);
  a {
    vertical-align: middle;
    text-decoration: none;
    margin-right: 20px;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  span {
    vertical-align: middle;
  }
}

@include media-min($breakpoint-type-small) {
  .breadcrumb-list {
    margin-bottom: 40px;
  }
}
