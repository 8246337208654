/* -Global bits and pieces
-----------------------------------------------------------------------------*/
html {
  scroll-behavior: smooth;
}

// Has tabindex for functional skiplinks, outline isn't needed in this case.
#main[tabindex] {
  outline: 0;
}

.main {
  .has-no-intro & {
    margin-top: $section-spacing * 1.5;

    @include media-min(rem(1100px)) {
      margin-top: $section-spacing * 2;
    }
  }
}

.post-edit-link {
  font-size: rem(14px);
}

// Class handled with JavaScript, which tries to take accessibility in mind.
// Sets hide-focus when a mouse is used and show focus when a keyboard is.
// Hide outlines for mouse users and make it more distinct for keyboard users.
a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
[tabindex] {
  .hide-focus & {
    outline: 0 !important;
  }
  .show-focus &:focus {
    outline: 3px dotted $color-body-foreground;
    outline-offset: 1px;
  }
  // Override outline colors when necessary
  .show-focus .lightbox &:focus {
    outline-color: #fff;
  }
}

// Effectively disable all animations if the user doesn't want them
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-iteration-count: 1 !important;
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}

.error-page-content {
  @include section-margin;

  min-height: 35vh;
}
