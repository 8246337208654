/* - List with info blocks, each block with image, text and a link
---------------------------------------------------------------------------- */
.info-blocks {
  @include section-margin;
}
.info-block {
  text-align: center;
}
.info-block-inner {
  position: relative;
}
.info-block-inner-wrap {
  padding: 20px 15px;
  box-shadow: none;
  transition: transform 0.15s ease, box-shadow 0.15s ease;
}

.overlay-link {
  &:hover,
  &:focus {
    ~ .info-block-inner-wrap {
      background-color: #fff;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
      transform: scale(1.02);
    }
  }
}
.info-block-image {
  margin: 0 auto 10px auto;
  max-width: 40px;
}

.info-block-title a {
  &:focus,
  &:hover {
    text-decoration: none;
  }
}
.info-block-text {
  margin-bottom: 0;
}

$info-blocks-gutter: 10px;
@include media-min(rem(550px)) {
  .info-blocks-list {
    @include grid-container($info-blocks-gutter, $info-blocks-gutter);
    @include grid-pull-bottom($info-blocks-gutter, $info-blocks-gutter);
  }

  .info-block {
    @include grid-item(1/2, $info-blocks-gutter, $info-blocks-gutter);
    @include grid-margin-bottom($info-blocks-gutter, $info-blocks-gutter);
  }
}

@include media-min(rem(1000px)) {
  .info-block {
    @include grid-item-size(1/3);
  }
}

@include media-min(rem(1350px)) {
  .info-block {
    @include grid-item-size(1/4);
  }
}
