/* -Contact form
-----------------------------------------------------------------------------*/

.contact-form-container {
  @include section-padding;
}
.contact-form-wrap {
  @include section-margin;

  padding-top: $page-margin;
  padding-bottom: $page-margin;

  @include when-page-margin-small {
    padding-top: $page-margin-small;
    padding-bottom: $page-margin-small;
  }

  background: linear-gradient(135deg, $color-blue, $color-blue-medium);
  color: #fff;

  label {
    margin-bottom: 10px;
    font-size: rem(18px);
    font-weight: bold;
  }
  input,
  textarea {
    width: 100%;
  }
  input {
    height: $form-control-height;
  }
  input[type='submit'] {
    max-width: 200px;
  }
  a {
    color: inherit;
  }

  /* Honeypot */
  #pot {
    display: none !important;
    visibility: hidden !important;
    width: 100%;
    margin-right: 0;
  }
  .error-honeypot ~ form #pot {
    display: block !important;
    visibility: visible !important;
  }

  .form-field {
    @include grid-margin-bottom;
  }
}

// Coworkers
.contact-form-coworkers {
  @include grid-container;

  margin-top: -$page-margin * 2;

  @include when-page-margin-small {
    margin-top: -$page-margin-small * 2;
  }

  .coworker-block {
    @include grid-item(1/4);
    @include section-margin-bottom;
  }
  .coworker-image-inner {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  }
}
.contact-form-inner {
  max-width: $wrap-width-extra-thin !important;
  @include page-margin-reset;
}

.contact-form-content {
  text-align: center;
}

.contact-form-terms {
  margin-bottom: 10px;
}

@include media-min($breakpoint-type-small) {
  .contact-form-content {
    font-size: rem(18px);
  }
}

@include media-min(rem(600px)) {
  .contact-form-wrap .text {
    @include grid-container;

    .form-field {
      @include grid-item(1/2);
    }
  }
  .contact-form-footer {
    display: flex;
  }
  .contact-form-terms {
    margin-right: 30px;
    margin-bottom: 0;
    font-size: rem(18px);
  }
}

@include media-min($breakpoint-type-medium) {
  .contact-form-content {
    font-size: rem(22px);
  }
}

@include media-min(rem(1000px)) {
  .contact-form-wrap {
    padding: -$page-margin * 2;
  }

  .contact-form-coworkers {
    margin-top: -$page-margin * 2;
  }
}
