/* -Intro
---------------------------------------------------------------------------- */
.intro-wrapper {
  position: relative;
  width: 100%;
}

.intro {
  display: flex;
  flex-direction: column;
}
.intro-item {
  position: relative;
  min-height: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;

  @include media-min(rem(800px)) {
    min-height: 60vh;
  }
}

.intro-bg {
  &,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.intro-content {
  position: relative;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2 !important;
}

.intro-content-title {
  padding: 10px 20px;
  font-size: rem(26px);
  background-color: rgba(39, 39, 43, 0.8);
}
