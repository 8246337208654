/* -Form elements
-----------------------------------------------------------------------------*/
input.error,
p.error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAIhSURBVDjLlZPrThNRFIWJicmJz6BWiYbIkYDEG0JbBiitDQgm0PuFXqSAtKXtpE2hNuoPTXwSnwtExd6w0pl2OtPlrphKLSXhx07OZM769qy19wwAGLhM1ddC184+d18QMzoq3lfsD3LZ7Y3XbE5DL6Atzuyilc5Ciyd7IHVfgNcDYTQ2tvDr5crn6uLSvX+Av2Lk36FFpSVENDe3OxDZu8apO5rROJDLo30+Nlvj5RnTlVNAKs1aCVFr7b4BPn6Cls21AWgEQlz2+Dl1h7IdA+i97A/geP65WhbmrnZZ0GIJpr6OqZqYAd5/gJpKox4Mg7pD2YoC2b0/54rJQuJZdm6Izcgma4TW1WZ0h+y8BfbyJMwBmSxkjw+VObNanp5h/adwGhaTXF4NWbLj9gEONyCmUZmd10pGgf1/vwcgOT3tUQE0DdicwIod2EmSbwsKE1P8QoDkcHPJ5YESjgBJkYQpIEZ2KEB51Y6y3ojvY+P8XEDN7uKS0w0ltA7QGCWHCxSWWpwyaCeLy0BkA7UXyyg8fIzDoWHeBaDN4tQdSvAVdU1Aok+nsNTipIEVnkywo/FHatVkBoIhnFisOBoZxcGtQd4B0GYJNZsDSiAEadUBCkstPtN3Avs2Msa+Dt9XfxoFSNYF/Bh9gP0bOqHLAm2WUF1YQskwrVFYPWkf3h1iXwbvqGfFPSGW9Eah8HSS9fuZDnS32f71m8KFY7xs/QZyu6TH2+2+FAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
}
input.success,
p.success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGrSURBVDjLvZPZLkNhFIV75zjvYm7VGFNCqoZUJ+roKUUpjRuqp61Wq0NKDMelGGqOxBSUIBKXWtWGZxAvobr8lWjChRgSF//dv9be+9trCwAI/vIE/26gXmviW5bqnb8yUK028qZjPfoPWEj4Ku5HBspgAz941IXZeze8N1bottSo8BTZviVWrEh546EO03EXpuJOdG63otJbjBKHkEp/Ml6yNYYzpuezWL4s5VMtT8acCMQcb5XL3eJE8VgBlR7BeMGW9Z4yT9y1CeyucuhdTGDxfftaBO7G4L+zg91UocxVmCiy51NpiP3n2treUPujL8xhOjYOzZYsQWANyRYlU4Y9Br6oHd5bDh0bCpSOixJiWx71YY09J5pM/WEbzFcDmHvwwBu2wnikg+lEj4mwBe5bC5h1OUqcwpdC60dxegRmR06TyjCF9G9z+qM2uCJmuMJmaNZaUrCSIi6X+jJIBBYtW5Cge7cd7sgoHDfDaAvKQGAlRZYc6ltJlMxX03UzlaRlBdQrzSCwksLRbOpHUSb7pcsnxCCwngvM2Rm/ugUCi84fycr4l2t8Bb6iqTxSCgNIAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
}

input[type='tel'],
input[type='url'],
input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'],
textarea {
  padding: 5px 25px 5px 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  -webkit-transition: border 0.15s linear;
  -moz-transition: border 0.15s linear;
  transition: border 0.15s linear;
}
input[type='tel']:focus,
input[type='url']:focus,
input[type='text']:focus,
input[type='email']:focus,
input[type='search']:focus,
input[type='password']:focus,
textarea:focus {
  border-color: #00589c;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

form input,
form textarea {
  display: block;
}
form input[type='submit'],
form input[type='reset'],
form input[type='radio'],
form input[type='checkbox'] {
  display: inline-block;
}
textarea {
  padding: 5px;
}

input.error,
textarea.error {
  border-color: #ee5f5b;
  background-color: #fae8e6;
  color: #bb4444;
}
input.success,
textarea.success {
  border-color: #57a957;
  background-color: #e7fae6;
  color: #336633;
}
input.error,
input.success {
  background-position: 97% 50%;
}
input.error:focus,
textarea.error:focus,
input.success:focus,
textarea.success:focus {
  background: #fff;
  color: #333;
}
input.error:focus,
textarea.error:focus {
  border-color: #e9322d;
}
input.success:focus,
textarea.success:focus {
  border-color: #458845;
}

p.error,
p.success {
  display: block;
  padding: 5px 35px;
  background-position: 10px 50%;
  font-size: 0.875em;
  text-shadow: none;
}
p.error {
  border-color: #eeaaaa;
  background-color: #f6e6e6;
  color: #bb4444;
}
p.success {
  border-color: #9fd684;
  background-color: #dff0d8;
  color: #336633;
}
span.error {
  display: block;
  background: #e02421;
  padding: 2px 5px;
  color: #ffffff;
  font-size: 0.75em;
  font-weight: 400;
}
