/* -Page content
-----------------------------------------------------------------------------*/
.entry-content {
  @include section-margin;

  @include media-min($breakpoint-type-small) {
    font-size: rem(18px);
  }

  @include media-min($breakpoint-type-medium) {
    font-size: rem(22px);
  }
}
.content-sidebar-container {
  @include wrap-wide;
  @include section-margin;

  .entry-content {
    @include page-margin-reset;

    margin-top: 0;
    margin-bottom: 0;
  }
}

@include media-min(rem(1200px)) {
  .content-sidebar-container {
    display: flex;

    article,
    .sidebar {
      flex-grow: 1;
    }
    article {
      flex-basis: 64%;
    }
    .sidebar {
      margin-left: 40px;
      flex-basis: 30%;
    }
  }
}

@include media-min(rem(1300px)) {
  .content-sidebar-container {
    .sidebar {
      margin-left: 80px;
    }
  }
}

// Kept old code since the classes is used in the WP editor manually.
@include media-min(rem(600px)) {
  .left-block,
  .right-block {
    float: left;
    width: 48%;
  }
  .left-block {
    margin-right: 4%;
  }
  .right-block {
    margin-left: 4%;
  }
  .left-block + .right-block {
    margin-left: 0;
  }
  .left-block + .right-block + p,
  .left-block + .right-block + ol,
  .left-block + .right-block + ul {
    clear: both;
  }
}
