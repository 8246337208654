/* -The main header area with logo and navigation
-----------------------------------------------------------------------------*/
$nav-menu-item-horizontal-padding: 13px;

.site-header {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.site-header-logo {
  max-width: 80px;
  position: absolute;
  top: 10px;
  z-index: 2;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
}
.menu-toggle {
  display: none;
  align-self: flex-start;
}

.menu-item-icon {
  display: none;
}

@include media-min($breakpoint-menu-toggle) {
  .site-header {
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
    color: $color-blue;
  }
  .site-header-logo {
    top: 20px;
  }
  // Nav menu
  .main-nav {
    margin-left: 110px;
    flex-grow: 1;
    font-size: rem(18px);

    > ul {
      text-align: right;

      > li {
        display: inline-block;
        margin-left: 5px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    a,
    button {
      display: block;
      padding: 5px $nav-menu-item-horizontal-padding 7px
        $nav-menu-item-horizontal-padding;
      color: inherit;
      text-decoration: none;

      &:focus {
        outline: 3px dotted $color-blue;
      }
    }

    > ul > li > a:hover,
    > ul > li > a:focus,
    > ul > li > button:focus,
    > ul > .current-menu-ancestor > a,
    > ul > .current-menu-ancestor > button,
    > ul > .current-menu-item > a {
      background-color: $color-blue;
      color: #fff;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    }

    .current-menu-ancestor > .sub-menu-dropdown-toggle::after,
    > ul > li > .sub-menu-dropdown-toggle:focus::after {
      background-image: svg-url(
        '<svg xmlns="http://www.w3.org/2000/svg" width="11.3" height="7.1" viewBox="0 0 11.3 7.1"><path fill="#fff" d="M0 1.4l5.7 5.7 5.6-5.7L9.9 0 5.7 4.2 1.4 0z"/></svg>'
      );
    }

    // Sub menu dropdown
    .sub-menu-dropdown-toggle {
      position: relative;
      // menu item padding, icon width, icon left spacing
      padding-right: calc(#{$nav-menu-item-horizontal-padding} + 12px + 6px);
      height: auto;
      border: 0;
      border-radius: 0;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      background-color: transparent;
      cursor: default;

      &::after {
        content: '';
        position: absolute;
        right: 13px;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 7px;

        background-image: svg-url(
          '<svg xmlns="http://www.w3.org/2000/svg" width="11.3" height="7.1" viewBox="0 0 11.3 7.1"><path fill="#001c75" d="M0 1.4l5.7 5.7 5.6-5.7L9.9 0 5.7 4.2 1.4 0z"/></svg>'
        );
        background-repeat: no-repeat;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        z-index: 100;
      }

      &:hover {
        background-color: #fff;
        color: $color-body-foreground;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

        &::before {
          background-color: #fff;
        }
        &::after {
          background-image: svg-url(
            '<svg xmlns="http://www.w3.org/2000/svg" width="11.3" height="7.1" viewBox="0 0 11.3 7.1"><path fill="#001c75" d="M0 1.4l5.7 5.7 5.6-5.7L9.9 0 5.7 4.2 1.4 0z"/></svg>'
          );
        }

        ~ .sub-menu {
          display: grid;
        }
      }
    }

    li.parent-item:hover,
    li.parent-item:focus {
      .sub-menu-dropdown-toggle {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        background-color: #fff;
        color: $color-body-foreground;

        &::before {
          background-color: #fff;
        }
        &::after {
          background-image: svg-url(
            '<svg xmlns="http://www.w3.org/2000/svg" width="11.3" height="7.1" viewBox="0 0 11.3 7.1"><path fill="#001c75" d="M0 1.4l5.7 5.7 5.6-5.7L9.9 0 5.7 4.2 1.4 0z"/></svg>'
          );
        }
      }
    }
    .sub-menu {
      display: none;

      &:hover,
      &:focus {
        display: grid;
      }
    }
    .menu-item-has-children {
      position: relative;
      text-align: left;

      .sub-menu {
        grid-auto-columns: auto;
        grid-auto-flow: column;
        column-gap: 26px;
        row-gap: 8px;

        position: absolute;
        left: 50%;
        width: 600px;
        transform: translateX(-50%);
        padding: 30px;
        z-index: 10;
        background-color: #fff;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);

        li:first-child {
          width: 100%;
          max-width: none;
          border-bottom: 2px solid;
          grid-area: 1 / 1 / span 3 / span 3 !important;

          a {
            @include h3;
          }
        }
        li {
          grid-column: 1;
        }

        .menu-item-icon {
          display: inline-block;
          margin-right: 15px;
          max-width: 25px;
        }

        @for $i from 0 through 40 {
          &.sub-menu--count-#{$i} {
            $half: ceil(($i + 1)/2) + 1;
            li:nth-child(n + #{$half}) {
              grid-column: 2;
            }
          }
        }
      }
      .current-menu-item a,
      a:hover,
      a:focus {
        background-color: $color-gray-light;
      }
    }
    .dropdown-open .sub-menu {
      display: grid;
    }
  }
}

@include media-min(rem(1100px)) {
  .site-header-logo {
    max-width: 120px;
  }
  // Nav menu
  .main-nav > ul > li {
    margin-left: 10px;
  }

  .sub-menu--cols-3 {
    @for $i from 0 to 40 {
      &.sub-menu--count-#{$i} {
        li {
          $i: $i;

          $third: ceil(($i)/3);
          $start: $third * 2;

          $first-end: $third + 1;

          $second-start: $third + 2;

          $third-start: $third + $third + 2;

          &:nth-child(n + 2):nth-child(-n + #{$first-end}) {
            grid-column: 1;
          }
          &:nth-child(n + #{$second-start}) {
            grid-column: 2;
          }
          &:nth-child(n + #{$third-start}) {
            grid-column: 3 !important;
          }
        }
      }
    }
  }

  // dropdown

  .main-nav .menu-item-has-children {
    .sub-menu {
      width: 840px !important;

      @for $i from 5 through 20 {
        &.sub-menu--col-rows-#{$i} {
          $start: 2 + $i;
          $end: $start + $i - 1;
          li:nth-child(n + #{$start}):nth-child(-n + #{$end}) {
            grid-column: 2;
          }
        }
      }

      @for $i from 5 through 20 {
        &.sub-menu--col-rows-#{$i} {
          $start: 2 + $i + $i;
          $end: $start + $i + $i;
          li:nth-child(n + #{$start}):nth-child(-n + #{$end}) {
            grid-column: 3;
          }
        }
      }
    }
  }
}

@include media-min(rem(1400px)) {
  .site-header-logo {
    max-width: 130px;
  }
}

@include media-max($breakpoint-menu-toggle) {
  .main-nav {
    display: none;
  }
}

/* ------------------- Toggled menu only ------------------- */
@include media-max($breakpoint-menu-toggle) {
  // ---------- Menu toggle button ----------
  .menu-toggle {
    position: relative;
    display: block;
    margin-left: auto;
    padding-right: 33px;
    padding: 0px 35px 0px 15px;
    height: rem(40px);
  }

  .menu-toggle-icon {
    top: 50%;
    right: 15px;

    &,
    &::before,
    &::after {
      position: absolute;
      width: 13px;
      height: 2px;
      background-color: currentColor;
      transition: background-color 0.15s ease;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      left: 0;
      transition: transform 0.15s ease;
    }
    &::before {
      top: 0;
      transform: translateY(-5px);
    }
    &::after {
      bottom: 0;
      transform: translateY(5px);
    }
    .nav-open & {
      background-color: rgba(255, 255, 255, 0);

      &::before {
        transform: translateY(0) rotate(-225deg);
      }
      &::after {
        transform: translateY(0) rotate(225deg);
      }
    }
  }

  // ---------- The open menu ----------
  .main-nav {
    visibility: hidden;
    position: absolute;
    top: 100%;
    right: 10px;
    min-width: rem(300px);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.06),
      0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
      0 8px 16px rgba(0, 0, 0, 0.07);
    border-radius: 2px;
    background-color: #fff; //$color-gray-light;
    overflow: hidden;
    transform: translateY(-5px);
    opacity: 0;
    transition: transform 0.2s ease, opacity 0.2s ease, visibility 0s ease 0.2s;

    .sub-menu-dropdown-toggle {
      display: none;
    }

    .sub-menu {
      padding: 0;
      border-bottom: 0;

      li:first-child {
        border-top: none;
      }
      li:not(:first-child) > a {
        padding: 9px 15px 9px 30px;
        font-size: rem(14px);
      }
    }

    .nav-open & {
      display: block;
      top: 100%;
      right: 10px;
      position: absolute;
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
      transform: translateY(0);
      z-index: 3;
    }
    li {
      display: block;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    > ul {
      > li:first-child {
        border-top: none;
      }
    }
    a {
      display: block;
      padding: 8px 15px;
      border-left: 5px solid transparent;
      color: $color-blue;
      text-decoration: none;
    }

    .current-menu-item > a {
      border-left-color: $color-blue;
    }
    .current-menu-ancestor > a {
      font-weight: $font-weight-bold;
    }
    li a:focus,
    li a:hover {
      background-color: $color-blue;
      color: #fff;
    }
  }
}
@include media-max(rem(450px)) {
  .main-nav {
    min-width: 0;
    left: 10px;
  }
}
