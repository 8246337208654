/* -Coworkers with image, name and job title
-----------------------------------------------------------------------------*/
$coworkers-template-column-gutter: 60px;
$coworkers-template-column-gutter-small: 30px;

.coworkers {
  @include section-margin;
}
.coworker-block {
  word-break: break-word;
}
.coworker-image {
  img {
    border: 3px solid #fff;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  }
}
.coworker-info {
  font-size: rem(14px);
}

// Coworkers start page template
.tpl-start {
  .coworkers-blocks {
    @include grid-container;
    @include grid-pull-bottom;
  }
  .coworker-block {
    @include grid-item(1/2);
    @include grid-margin-bottom;
  }

  .coworker-image {
    margin-bottom: 10px;

    a:hover,
    a:focus {
      img {
        border-color: #ddd;
      }
    }
  }
  .coworker-title {
    font-size: rem(16px);
  }

  @include media-min(rem(600px)) {
    .coworker-block {
      @include grid-item(1/3);
    }
  }

  @include media-min(rem(1200px)) {
    .coworkers {
      @include wrap-wide;
    }
    .coworker-block {
      @include grid-item(1/6);
    }
    .coworker-info {
      font-size: rem(16px);
    }
  }
}

// Coworkers template
.tpl-coworkers {
  .coworkers-blocks {
    @include grid-container(
      $coworkers-template-column-gutter,
      $coworkers-template-column-gutter-small
    );
    @include grid-pull-bottom(
      $coworkers-template-column-gutter,
      $coworkers-template-column-gutter-small
    );

    max-width: 1240px;
  }
  .coworker-block {
    @include grid-item(
      1,
      $coworkers-template-column-gutter,
      $coworkers-template-column-gutter-small
    );
    @include grid-margin-bottom(
      $coworkers-template-column-gutter,
      $coworkers-template-column-gutter-small
    );

    display: flex;
    word-break: break-word;
  }
  .coworker-image {
    max-width: 80px;
    margin-right: 15px;
    flex-shrink: 0;
  }
  .skills {
    margin-bottom: 10px;
  }

  @include media-min(rem(500px)) {
    .coworker-image {
      max-width: 100px;
      margin-right: 30px;
    }
  }
  @include media-min(rem(600px)) {
    .coworker-image {
      max-width: 130px;
      margin-right: 30px;
    }
    .skills {
      margin-bottom: 20px;
    }
  }

  @include media-min(rem(850px)) {
    .coworker-block {
      @include grid-item-size(1/2);
    }
  }

  @include media-min(rem(1000px)) {
    .coworker-info {
      font-size: rem(18px);
    }
    .coworker-image {
      max-width: 160px;
      margin-right: 30px;
    }
    .coworker-info {
      flex-grow: 1;
    }
  }
}
