/* -Text widget with text and button
-----------------------------------------------------------------------------*/
.revisorscentrum-text-widget {
  padding: 40px;
  color: #fff;

  &.text-widget-blue {
    background: linear-gradient(135deg, $color-blue, $color-blue-medium);
  }

  &.text-widget-green {
    background: linear-gradient(135deg, $color-green, $color-green-light);
  }

  .widget-title {
    margin-bottom: 10px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .btn {
    &:focus {
      outline: 3px dotted #fff;
    }
  }
}

.revisorscentrum-text-widget + .revisorscentrum-text-widget {
  margin-top: 20px;
}

@include media-min($breakpoint-type-small) {
  .revisorscentrum-text-widget {
    font-size: rem(18px);
  }
  .revisorscentrum-text-widget + .revisorscentrum-text-widget {
    margin-top: 40px;
  }
}

@include media-min(rem(1350px)) {
  .revisorscentrum-text-widget {
    font-size: rem(22px);
  }
}
